<template>
  <div v-if="list.length > 0">
    <h6 class="text-sm uppercase font-bold">
      {{ title }}
    </h6>
    <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 mt-2">
      <div
        v-for="(dict, key) in list"
        :key="key"
      >
        <div class="flex items-center text-sm font-semibold">
          <div class="led led-orange flex-shrink-0" :data-plcstatus="dict && dict.value" />
          <h5 class="truncate">
            {{ dict.label }}
          </h5>
        </div>
        <!-- <div class="bg-concrete text-xs mt-0.5 p-0.5" v-if="state.isTimestampVisible && dict && dict.timestamp">
          {{ formatDate(dict.timestamp) }}
        </div>
        <div class="bg-concrete text-xs mt-0.5 p-0.5" v-else>
          <span>n/a</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BinLogicConfig',
  props: ['title', 'list']
}
</script>
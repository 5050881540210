<template>
  <div class="relative px-2 md:px-4 w-full pb-2 md:pb-4">
    <alert-response-warning v-if="state.hasError" :error="state.error" />
    <div v-else-if="state.isLoading" class="sticky inset-0 h-full bg-gray-50 opacity-75">
      <div class="flex justify-center items-center w-full h-full py-5">
        <icon-loading class="text-blumine w-5 h-5" />
      </div>
    </div>
    <div v-else>
      <ice-maker-status-table :makers-state="state.makersState" :disabled="!isOnline" view="vertical" />
    </div>
  </div>
</template>

<script>
import { reactive, watch, onBeforeUnmount } from 'vue'
import http from '@/services/http.js'
import { IconLoading } from '@/components/icons'
import AlertResponseWarning from '@/components/alerts/ResponseWarning.vue'
import IceMakerStatusTable from '@/components/schedules/IceMakerStatusTable.vue'

export default {
  name: 'machines-detail',

  props: ['machineId', 'isOnline'],

  components: {
    IconLoading,
    AlertResponseWarning,
    IceMakerStatusTable,
  },

  setup(props) {
    let pollingInterval = null
    const state = reactive({
      hasError: false,
      error: null,
      isLoading: true,
      makersState: {},
    })

    const getSchedule = async (isPolling = false) => {
      if (!isPolling) {
        state.isLoading = true
      }

      const machineId = props.machineId
      try {
        const obj = await http.get(`machine/${machineId}/schedule`)
          .then(({ data }) => {
            return {
              ...data,
              location_id: machineId,
            }
          })

        state.makersState = {
          location_id: machineId,
          can_edit: obj.can_edit,
          timezone: obj.timezone,

          im_1_running: obj.im_1_running,
          im_2_running: obj.im_2_running,
          im_1_vends_since_off: obj.im_1_vends_since_off,
          im_2_vends_since_off: obj.im_2_vends_since_off,
          override: obj.override,

          bin_logic: obj.bin_logic,

          im_1_action_id: 'im1',
          im_2_action_id: 'im2',
        }
      } catch (e) {
        state.hasError = true
        state.error = e
      }

      state.isLoading = false
    }

    const startPolling = () => {
      pollingInterval = setInterval(() => {
        getSchedule(true)
      }, 10000)
    }

    getSchedule()
    startPolling()

    watch(
      () => props.machineId,
      () => {
        if (props.machineId) {
          state.hasError = false
          getSchedule()
        }
        else {
          state.hasError = true
          state.error = null
        }
      }
    )

    onBeforeUnmount(() => {
      clearInterval(pollingInterval)
      pollingInterval = null
    })

    return {
      state
    }
  }
}
</script>

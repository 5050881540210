<template>
  <div>
    <button
      :id="id"
      :disabled="disabled"
      @click="onClick"
      :class="[classes]"
      class="btn-plc"
      type="button"
    >
      {{ buttonText }}
    </button>
    <div>
      <error v-if="error">{{ error }}</error>
    </div>
  </div>
</template>

<script>
import Error from '@/components/form/Error.vue'
import { computed } from 'vue'

export default {
  components: {
    Error
  },

  emits: ['update:modelValue', 'change'],

  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String
    },
    modelValue: {
      default: false
    },
    value: {
      default: false
    },
    error: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const enabled = computed(() => props.value || props.modelValue)

    const buttonState = computed(() => {
      if (props.isLoading) {
        return 'loading'
      }
      else if (props.disabled) {
        return 'disabled'
      }
      else {
        return enabled.value ? 'on' : 'off'
      }
    })

    const colorClasses = computed(() => ({
      off: 'bg-red-600 text-white hover:bg-red-700',
      on: 'bg-green-500 text-white hover:bg-green-600',
      disabled: 'bg-blumine cursor-not-allowed',
      loading: 'bg-gray-500 text-white cursor-not-allowed',
    }[buttonState.value]))

    const buttonText = computed(() => ({
      off: 'Icemaker OFF',
      on: 'Icemaker ON',
      disabled: 'N/A',
      loading: 'Loading',
    }[buttonState.value]))

    const onClick = () => {
      emit('update:modelValue', !props.modelValue)
      emit('change', !props.modelValue)
    }

    return {
      onClick,
      classes: colorClasses,
      enabled,
      buttonText
    }
  }
}
</script>

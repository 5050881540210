<template>
  <div class="w-full xxl:w-3/4">
    <errors :items="machine.errors" />

    <!-- PLC Status -->
    <card title="PLC Status" :is-loading="isLoading" :is-online="machine.plc_last_update" :last-status="machine.plc_last_update" class="relative">
      <div class="w-full lg:w-1/4 px-2 md:px-4 lg:order-2">
        <div class="flex flex-wrap justify-center bg-white p-2 md:p-4 mb-2 md:mb-4 rounded-md relative">
          <div v-show="!machine.is_online" class="absolute inset-0 bg-gray-100 opacity-50 z-30"></div>
          <plc-buttons
            :machine-id="machineId"
            :is-in-maintenance-mode="machine.is_in_maintenance_mode"
            :is-hold-free-enabled="machine.is_hold_free_enabled"
            :is-disclaimer-accepted="machine.scheduled_reset_disclaimer_accepted"
            :reset-at="machine.scheduled_reset_at"
          />
        </div>
      </div>

      <div class="relative w-full lg:w-3/4 px-2 md:px-4 lg:order-1">
        <div v-show="!machine.is_online" class="absolute inset-0 bg-gray-100 opacity-50 z-40"></div>
        <div class="mb-2">
          <plc header="OUTPUT" :is-loading="isLoading" :registers="machine.registers.output" :is-legend-visible="state.isLegendVisible" />
        </div>
        <div class="mb-4">
          <plc header="INPUT" :is-loading="isLoading" :registers="machine.registers.input" :is-legend-visible="state.isLegendVisible" />
        </div>
      </div>

      <div class="flex items-center w-full space-x-2 order-3 p-2 md:p-4">
        <label class="text-sm font-bold">PLC Legend</label>
        <toggle v-model="state.isLegendVisible" @change="savePlcLegendState" class="flex" />
      </div>
    </card>

    <!-- MDBPro Status -->
    <card title="MDBPro Status" :is-online="machine.is_online" :last-status="machine.mdb_pro_last_update" class="mt-0.5 sm:mt-4 lg:mt-6">
      <mdb-pro :machine="machine" :is-loading="isLoading" />
    </card>

    <card title="Readings" v-if="machine.holding_registers" :is-online="machine.is_online" class="mt-0.5 sm:mt-4 lg:mt-6">
      <holding-registers :machine="machine" :is-loading="isLoading" />
    </card>

    <!-- IceMakers Status -->
    <card title="Ice Makers Status" :is-online="machine.is_online" class="mt-0.5 sm:mt-4 lg:mt-6">
      <ice-maker-status :machine-id="machineId" :is-online="machine.is_online" />
    </card>

    <!-- Machine Maintanance -->
    <div class="px-2 bg-concrete rounded-md shadow-sm mt-4 lg:mt-6 sm:px-0">
      <action-maintenance
        :machine-id="machineId"
        :is-in-maintenance-mode="machine.is_in_maintenance_mode"
        :is-loading="isLoading"
      />
    </div>
  </div>

  <div class="w-full xxl:w-1/4 flex flex-col mt-2 sm:mt-6 xxl:mt-0 xxl:pl-6 relative">
    <div class="xxl:sticky xxl:top-20">
      <!-- Load Bags -->
      <div
        v-if="machine.bag_counts_enabled"
        class="relative py-3 flex items-start sm:items-center justify-between w-full border-b px-4 sm:px-1"
      >
        <div v-show="!machine.is_online" class="absolute inset-0 bg-gray-100 opacity-50 z-40 rounded-t-md"></div>
        <div class="flex flex-col sm:flex-row sm:items-center">
          <h3 class="leading-none mb-3 sm:mb-0 sm:w-24">Bag Count</h3>
          <action-load-bags :machine-id="machineId" />
        </div>
        <div class="text-3xl font-bold text-red-600">
          <icon-loading v-if="isLoading" class="text-blumine w-5 h-5" />
          <span v-else>
            {{ machine.bag_count }}
          </span>
        </div>
      </div>

      <!-- Cash Out -->
      <div class="relative py-3 flex items-start sm:items-center justify-between w-full border-b px-4 sm:px-1">
        <div v-show="!machine.is_online" class="absolute inset-0 bg-gray-100 opacity-50 z-40"></div>
        <div class="flex flex-col sm:flex-row sm:items-center">
          <h3 class="leading-none mb-3 sm:mb-0 sm:w-24">Cash Sales</h3>
          <action-cash-out :machine-id="machineId" />
        </div>
        <div class="text-3xl font-bold text-green-500">
          <icon-loading v-if="isLoading" class="text-blumine w-5 h-5" />
          <span v-else>
            {{ formatMoney(machine.sales_value / 100 || 0) }}
          </span>
        </div>
      </div>

      <!-- Machine quick log -->
      <div class="w-full mt-4 lg:mt-8">
        <log
          :machine-id="machineId"
          :entries="logs"
          :is-loading="isLoading"
        />
      </div>
    </div>
  </div>

</template>

<script>
import { reactive } from 'vue'
import money from '@/helpers/money.js'
import Card from '@/components/machines/Card.vue'
import Toggle from '@/components/form/Toggle.vue'
import Plc from '@/components/machines/Plc.vue'
import PlcButtons from '@/components/machines/PlcButtons.vue'
import MdbPro from '@/components/machines/MdbPro.vue'
import HoldingRegisters from '@/components/machines/HoldingRegisters.vue'
import Log from '@/components/machines/Log.vue'
import ActionLoadBags from '@/components/machines/actions/LoadBags.vue'
import ActionCashOut from '@/components/machines/actions/CashOut.vue'
import { IconLoading } from '@/components/icons'
import Errors from '@/components/machines/Errors.vue'
import ActionMaintenance from '@/components/machines/actions/Maintenance.vue'
import IceMakerStatus from '@/components/schedules/IceMakerStatus.vue'

export default {
  name: 'machines-detail',

  props: ['machineId', 'machine', 'logs', 'isLoading'],

  components: {
    Card,
    Plc,
    PlcButtons,
    MdbPro,
    Log,
    ActionLoadBags,
    ActionCashOut,
    ActionMaintenance,
    IconLoading,
    Errors,
    Toggle,
    IceMakerStatus,
    HoldingRegisters,
  },

  setup() {
    const plcLegendLocalStorageKey = 'machine_detail_show_plc_legend'
    const plcLegendInitialState = localStorage.getItem(plcLegendLocalStorageKey)
    const state = reactive({
      isLegendVisible: plcLegendInitialState === 'yes',
    })

    const savePlcLegendState = (value) => {
      localStorage.setItem(plcLegendLocalStorageKey, value ? 'yes' : 'no')
    }

    return {
      state,
      formatMoney: money.format,
      savePlcLegendState,
    }
  }
}
</script>

import { Auth } from '@/services/auth'
import { reactive } from 'vue'

const baseUrl = process.env.VUE_APP_WS || 'wss://62c3drsuyf.execute-api.us-west-2.amazonaws.com/dev'

export class Ws {
  constructor() {
    this.ws = {}
    this.url = baseUrl
    this.listeners = reactive({})
  }

  async start() {
    const token = await this.token()
    this.ws = new WebSocket(`${this.url}?token=${token}`)
    this.ws.onmessage = (e) => {
      this.handleListener(e)
    }
  }

  close() {
    if (typeof this.ws.close === 'function') {
      this.ws.close(1000)
    }
  }

  async subscribeToMachine(id) {
    if (this.ws.readyState !== WebSocket.OPEN) await this.start()

    this.ws.onopen = () => {
      this.listeners['connection'] ? this.listeners['connection'](this) : null

      this.ws.send(
        JSON.stringify({
          type: 'subscribe',
          location_id: id
        })
      )
    }
  }

  async token() {
    const token = await Auth.token()

    return token || this.redirectToLogin()
  }

  redirectToLogin() {
    window.location.href = '/login'
  }

  send(payload) {
    this.ws.send(payload)

    return this
  }

  handleListener(e) {
    const data = JSON.parse(e.data)

    if (! data.type) {
      this.listeners['error'] ? this.listeners['error'](data) : null
    }

    this.listeners[data.type] ? this.listeners[data.type](data.message) : null
  }

  on(type, clb) {
    this.listeners[type] = clb

    return this
  }
}
